<template>
  <form>
    <h1>Id utilisateurs: {{user.id}}</h1>
    <br>

    <v-text-field
      v-model="user.phone"
      label="Téléphone"
      @change="user.phone ? user.messageBirdStatus = 2 : ''"
    />

    <v-select
      v-model="user.messageBirdStatus"
      :items="messagesBirdStatus"
      item-text="label"
      item-value="value"
      label="Vérification du numéro téléphone"
    ></v-select>

    <v-text-field
      v-model="user.email"
      type="email"
      label="Email"
      :rules="emailRules"
    />
    <v-text-field
      v-model="user.firstname"
      label="Prénom"
      :rules="textRules"
    />
    <v-text-field
      v-model="user.lastname"
      label="Nom"
      :rules="textRules"
    />
    <v-select
      v-model="user.type"
      :items="usersTypes"
      item-text="label"
      item-value="id"
      label="Type"
      required
    />
    <v-checkbox
      v-model="user.admin"
      label="Administrateur"
    />
    <v-select
      v-model="user.status"
      :items="usersStatus"
      item-text="label"
      item-value="value"
      label="Choissisez le statut de l'utilisateur"
    ></v-select>
    <v-btn
      class="info"
      @click="submit()"
    >
      Valider
    </v-btn>
  </form>
</template>

<script>
import axios from 'axios'
import axiosGetDataWithUid from '@/apiCall/axiosGetDataWithUid.js'


export default {
    data:() => ({
      user: {},
      usersTypes: [],
      emailRules: [ 
        v => !!v || 'Champs requis',  
        v => /.+@.+/.test(v) || 'E-mail doit être valide' 
      ],
      textRules: [
        v => !!v || 'Champs requis'
      ],
      userStatus:"",
      usersStatus: [
        {
          value:1,
          label:"1 - Non vérifié" 
        },
        {
          value:2,
          label: "2 - Validé" 
        },
        {
          value:3,
          label: "3 - Banni"
        },
      ],
      messageBirdStatus:"",
      messagesBirdStatus: [
        {
          value:1,
          label:"1 - En cours" 
        },
        {
          value:2,
          label: "2 - Validé" 
        },
      ],
      newStatus:Number,
    }),
    created(){
      axiosGetDataWithUid('/users/', this.$route.params.userUid)
      .then(Response => {

        this.user = Response.data;

        if(this.user.status === 1) {
          this.userStatus = "1 - non vérifié"
        }
        if(this.user.status === 2) {
          this.userStatus =  "2 - validé"
        }
        if(this.user.status === 3) {
          this.userStatus = "3 - banni"
        }

        if(this.user.messageBirdStatus === 1) {
          this.messageBirdStatus =  "1 - En cours" 
        }
        if(this.user.messageBirdStatus === 2) {
          this.messageBirdStatus = "2 - Validé"
        }
        
        this.user.phone = (this.user.phone  ? Response.data.phone : "") 
        
      })
      axios.get(process.env.VUE_APP_APIURL + '/usersTypes' , {
      headers :{
          'Access-Token': localStorage.getItem('token')
        }
      })
      .then(Response => {
        this.usersTypes = Response.data
      })
    },

    methods: {
      submit () {
          axios.put(process.env.VUE_APP_APIURL + '/users/' + this.$route.params.userUid, { 
            email: this.user.email !== '' ? this.user.email : undefined,
            phone: this.user.phone !== null ? this.user.phone : undefined,
            firstname: this.user.firstname !== '' ? this.user.firstname : undefined,
            lastname: this.user.lastname !== '' ? this.user.lastname : undefined,
            type: this.user.type !== null ? this.user.type : undefined,
            status: this.user.status ? this.user.status : undefined,
            messageBirdStatus: this.user.messageBirdStatus ? this.user.messageBirdStatus : undefined,
            admin: this.user.admin !== null ? this.user.admin : undefined,
          },{
          headers :{
            'Access-Token': localStorage.getItem('token')
          }
        }).then(res => {

						if ( res.status == 200 ) {
              document.location.reload();
            }
          });
      },
    },

    
}
</script>

<style>
  form{
    max-width: 600px;
    margin: auto;
  }
</style>